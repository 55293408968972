<template>
  <section v-if="asuulgas">
    <v-card
      class="border-radius-xl card-shadow mt-10 mx-4"
      v-for="(asuulga, aindex) in asuulgas"
      :key="'asuulga' + aindex"
    >
      <h3 class="mx-4 pt-5">
        Сэдэв: <span>{{ asuulga.title }}</span>
      </h3>

      <v-row class="mx-9 mt-2">
        <v-chip class="bg-gradient-success white--text">Т</v-chip>
        <span class="mt-1 mr-5 ml-1">Тийм</span>
        <v-chip class="bg-gradient-danger white--text">Ү</v-chip>
        <span class="mt-1 mr-5 ml-1">Үгүй</span>
        <v-chip class="bg-gradient-warning white--text">З</v-chip>
        <span class="mt-1 mr-5 ml-1">Заримдаа</span>
      </v-row>

      <v-row v-if="asuulgas" class="mt-n3">
        <v-col md="10" lg="12" class="mx-auto">
          <v-card
            v-for="(teacher, tindex) in asuulga.teachers"
            :key="tindex"
            class="mx-10 mb-4 asuulga"
          >
            <h3 class="py-2 my-2">
              {{ teacher.firstName }}
            </h3>
            <table v-if="teacher.programs && teacher.programs.length > 0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Заадаг бүлэг</th>
                  <th>Xичээл заадаг с.тоо</th>
                  <th>Xамрагдсан с.тоо</th>
                  <th>Xамрагдсан хувь</th>
                  <th
                    class="pa-0 text-center"
                    v-for="(question, qindex) in teacher.programs[0].questions"
                    :key="'hq' + qindex"
                  >
                    <span>
                      {{ "Асуулт " + (qindex + 1) }}
                    </span>
                    <div class="d-flex flex-row">
                      <v-col
                        cols="4"
                        class="pa-0 pt-1"
                        style="
                          border-top: 1px solid #e0e0e0;
                          border-right: 1px solid #e0e0e0;
                        "
                      >
                        Т
                      </v-col>
                      <v-col
                        cols="4"
                        class="pa-0 pt-1"
                        style="
                          border-top: 1px solid #e0e0e0;
                          border-right: 1px solid #e0e0e0;
                        "
                      >
                        Ү
                      </v-col>
                      <v-col
                        cols="4"
                        class="pa-0 pt-1"
                        style="border-top: 1px solid #e0e0e0"
                        >З</v-col
                      >
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(program, pindex) in teacher.programs" :key="pindex">
                  <td>{{ pindex + 1 }}</td>
                  <td style="width: 5%">
                    {{ program.name }}
                  </td>

                  <td v-if="selectedClassGroup">
                    {{ program.numberOfStudents }}
                  </td>
                  <td v-else>{{ program.numberOfStudents }}</td>
                  <td
                    title="Энд дарж хамрагдсан сурагчдаа харах боломжтой"
                    style="width: 50px; color: red; cursor: pointer"
                    @click="getParticipantsData(teacher, asuulga, pindex)"
                  >
                    {{ program.numberOfparticipants }}
                  </td>
                  <td style="width: 50px">
                    {{
                      (
                        (program.numberOfparticipants /
                          program.numberOfStudents) *
                        100
                      ).toFixed(0)
                    }}%
                  </td>
                  <template v-if="program.questions">
                    <td
                      class="pa-0"
                      v-for="(question, qindex) in program.questions"
                      :key="'q' + qindex"
                    >
                      <div
                        class="d-flex flex-row text-center"
                        style="line-height: 2.5"
                      >
                        <v-col
                          cols="4"
                          class="pa-0 pt-1"
                          style="border-right: 1px solid #e0e0e0"
                        >
                          {{ question.yesNumber }}
                        </v-col>
                        <v-col
                          cols="4"
                          class="pa-0 pt-1"
                          style="border-right: 1px solid #e0e0e0"
                        >
                          {{ question.noNumber }}
                        </v-col>
                        <v-col cols="4" class="pa-0 pt-1">{{
                          question.sometimesNumber
                        }}</v-col>
                      </div>
                    </td>
                  </template>
                  <!-- <td>{{ props.item.programs.length }}</td>
              <td v-if="props.item.programs && props.item.programs.length > 0">
                <tr v-for="(program, pindex) in props.item.programs" :key="pindex">
                  <td>{{ program.name }}</td>
                </tr>
              </td> -->
                </tr>
                <tr>
                  <td style="width: 2%; border-right: 1px solid white"></td>
                  <td style="border-right: 1px solid white"></td>
                  <td style="width: 2%; border-right: 1px solid white">
                    {{ teacher.toBeNumberOfParticipants }}
                  </td>
                  <td
                    title="Энд дарж хамрагдсан сурагчдаа харах боломжтой"
                    style="
                      border-right: 1px solid white;
                      color: red;
                      cursor: pointer;
                    "
                    @click="getParticipantsData(teacher, asuulga, null)"
                  >
                    {{ teacher.numberOfparticipants }}
                  </td>
                  <td>
                    {{
                      (
                        (teacher.numberOfparticipants /
                          teacher.toBeNumberOfParticipants) *
                        100
                      ).toFixed(0)
                    }}%
                  </td>
                  <td
                    class="pa-0"
                    v-for="(question, qindex) in teacher.questions"
                    :key="'q' + qindex"
                  >
                    <div
                      class="d-flex flex-row text-center"
                      style="line-height: 2.5"
                    >
                      <v-col
                        cols="4"
                        class="pa-0 pt-1"
                        style="border-right: 1px solid white"
                      >
                        {{ question.yesNumberTotal }}
                      </v-col>
                      <v-col
                        cols="4"
                        class="pa-0 pt-1"
                        style="border-right: 1px solid white"
                      >
                        {{ question.noNumberTotal }}
                      </v-col>
                      <v-col
                        cols="4"
                        style="border-right: 1px solid white"
                        class="pa-0 pt-1"
                        >{{ question.sometimesTotal }}</v-col
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <h2 v-else class="text-center red--text mb-10">
            Танай сургуулиас оролцоогүй байна!
          </h2> -->
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col md="8" class="mx-auto">
          <v-data-table
            loading
            loading-text="Мэдээлэл байxгүй байна"
            class="elevation-1"
          ></v-data-table>
        </v-col>
      </v-row>

      <v-dialog
        v-model="seeParticipantsDialog"
        v-if="selectedParticipants"
        width="500"
      >
        <v-card class="pa-4">
          <v-data-table
            :items="selectedParticipants"
            :headers="[]"
            hide-default-header
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:header="{ props: {} }">
              <tr>
                <th class="text-start pl-3">No</th>
                <th class="text-start pl-4">Нэр</th>
                <th class="text-start pl-4">Илгээсэн Он сар</th>
              </tr>
            </template>
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.index + 1 }}</td>
                <td>
                  {{ props.item.studentFirstName }}.
                  <span v-if="props.item.studentLastName">{{
                    props.item.studentLastName
                  }}</span>
                </td>
                <td style="font-size: 12px">
                  {{ props.item.answeredAt.toDate().toLocaleString() }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-card>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    seeParticipantsDialog: false,
    selectedParticipants: null,
    analyzeSchoolScoresDialog: false,
    selectedStudent: null,
    showStudentAnswerDialog: false,
    analyzeScoresDialog: false,
    school: null,
    onlyOurSchool: true,
    downloadLoading: false,
    resultCollectionName: "results-simplified", //simplified
    asuulgas: null,
    schools: null,
    maxTotalScore: 0,
    sumOfAverageTotalScores: 0,
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,

    results: null,
    categories: null,
    loading: true,
    rules: [],

    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Xамрагдсан тоо",
        align: "start",
        sortable: true,
        value: "numberOfparticipants",
      },
    ],
    showStudentTimeDialog: false,
    selectedClassGroup: null,
  }),

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    isEyesh() {
      return fb.EYESH_APP;
    },
  },
  methods: {
    getParticipantsData(teacher, asuulga, pindex) {
      if (pindex != null) {
        asuulga.ref
          .collection("answered")
          .where("teacherName", "==", teacher.firstName)
          .where("classGroupName", "==", teacher.programs[pindex].name)
          .get()
          .then((docs) => {
 
            this.selectedParticipants = [];
            docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;
 
              this.selectedParticipants.push(stud);
            });
            this.seeParticipantsDialog = !this.seeParticipantsDialog;
            console.log(this.selectedParticipants);
          });
      } else {
        asuulga.ref
          .collection("answered")
          .where("teacherName", "==", teacher.firstName)
          .get()
          .then((docs) => {
 
            this.selectedParticipants = [];
            docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;
 
              this.selectedParticipants.push(stud);
            });
            this.seeParticipantsDialog = !this.seeParticipantsDialog;
            console.log(this.selectedParticipants);
          });
      }
    },
  },
  created() {
    for (var i = 1; i <= 10; i++) {
      this.headers.push({
        text: "q" + i,
        align: "start",
        sortable: true,
        value: "q",
        width: "2%",
      });
    }
    if (this.userData.school) {
      this.userData.school.ref.get().then((doc) => {
        this.school = doc.data();
        this.school.id = doc.id;
        this.school.ref = doc.ref;
      });
    }

    this.userData.school.ref
      .collection("asuulga")
      .get()
      .then((docs) => {
        this.asuulgas = [];
        docs.forEach((doc) => {
          let asuulga = doc.data();
          asuulga.id = doc.id;
          asuulga.ref = doc.ref;
          asuulga.teachers = null;

          var teacherName;
          if (this.userData.lastName) {
            teacherName =
              this.userData.lastName[0] + "." + this.userData.firstName;
          } else {
            teacherName = this.userData.firstName;
          }

          asuulga.ref
            .collection("results")
            .doc(teacherName)
            .onSnapshot((doc) => {
              asuulga.teachers = [];

              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              teacher.programs = null;

              teacher.ref.collection("programs").onSnapshot((docs) => {
                teacher.programs = [];
                teacher.toBeNumberOfParticipants = 0;
                var counter = 0;

                docs.forEach(async (doc) => {
                  let program = doc.data();
                  program.id = doc.id;
                  program.ref = doc.ref;
                  var ids = program.name.split("-");
                  if (ids.length == 1) {
                    console.log(program.name[0] + program.name[1]);
                    await this.userData.school.ref
                      .collection("departments")
                      .doc("department-" + program.name[0] + program.name[1])
                      .collection("programs")
                      .where("fullName", "==", program.name)
                      .get()
                      .then((docs) => {
                        docs.forEach((doc) => {
                          program.numberOfStudents =
                            doc.data().numberOfStudents;
                        });
                      });
                  } else {
                    console.log(ids);
                    await this.userData.school.ref
                      .collection("departments")
                      .doc("department-" + ids[0])
                      .collection("programs")
                      .where("fullName", "==", program.name)
                      .get()
                      .then((docs) => {
                        docs.forEach((doc) => {
                          program.numberOfStudents =
                            doc.data().numberOfStudents;
                        });
                      });
                  }

                  teacher.programs.push(program);
                  teacher.toBeNumberOfParticipants =
                    teacher.toBeNumberOfParticipants + program.numberOfStudents;

                  console.log(docs.size, ++counter);
                  if (docs.size == ++counter) {
                    setTimeout(() => {
                      this.loading = false;
                    }, 1000);
                  } else {
                    this.loading = true;
                  }
                });
              });
              asuulga.teachers.push(teacher);
            });
          this.asuulgas.push(asuulga);
        });
      });
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.asuulga table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.asuulga td,
.asuulga th {
  font-size: 12px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 2px;
}

.asuulga tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
